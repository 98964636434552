
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/color-picker/BasicUsage.vue";
import EUIAlpha from "@/views/resources/documentation/element-ui/form/color-picker/Alpha.vue";
import EUIPredefinedColors from "@/views/resources/documentation/element-ui/form/color-picker/PredefinedColors.vue";
import EUISizes from "@/views/resources/documentation/element-ui/form/color-picker/Sizes.vue";

export default defineComponent({
  name: "color-picker",
  components: {
    EUIBasicUsage,
    EUIAlpha,
    EUIPredefinedColors,
    EUISizes
  },
  setup() {
    setCurrentPageTitle("ColorPicker");
  }
});
